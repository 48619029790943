<template>
  <Layout>
    <div class="row" style="margin-bottom: 250px" v-if="this.checkedRest">
      <div class="col-md-6 offset-lg-3 pb-5">
        <div class="row">
          <div class="col-md-10 offset-md-1">
            <div class="registration-form pb-5">
              <p class="welcome mb-4">{{$t("reset_password")}}</p>
              <div style="padding-left: 6%; padding-right: 6%">
                <form class="needs-validation" @submit.prevent="tryToRegisterIn" >
                  <b-alert v-model="registerSuccess" class="mt-3" variant="success" dismissible>{{regSuccess}}</b-alert>
                  <b-alert v-model="isRegisterError" class="mt-3" variant="danger" dismissible>{{ regError }}</b-alert>
                  <div v-if="notification.message" :class="'alert ' + notification.type">{{ notification.message }}</div>

                  <div class="mt-4" v-if="this.isSeenPassword" >
                    <label for="password_eye" class="form-label">{{$t("new_password")}}</label>
                    <div class="form-icon right">
                      <input v-model="password" type="text" class="form-control form-control-icon" id="password_eye"
                             :class="{'is-invalid': submitted && v$.password.$error,}"
                             :placeholder="$t('type_in')">
                      <i @click="OnClickedPasswordVisibilityChange" class="ri-eye-line red-color cursor-pointer"></i>
                    </div>
                    <div v-for="(item, index) in v$.password.$errors" :key="index" class="red-color">
                      <span v-if="item.$message">{{ item.$message }}</span>
                    </div>
                  </div>
                  <div class="mt-4" v-else>
                    <label for="password_close_eye" class="form-label">{{$t("new_password")}}</label>
                    <div class="form-icon right">
                      <input v-model="password" v-if="!this.isSeenPassword" type="password" class="form-control form-control-icon" id="password_close_eye"
                             :class="{'is-invalid': submitted && v$.password.$error,}"
                             :placeholder="$t('type_in')">
                      <i @click="OnClickedPasswordVisibilityChange" class="ri-eye-off-line red-color cursor-pointer"></i>
                    </div>
                    <div v-for="(item, index) in v$.password.$errors" :key="index" class="red-color">
                      <span v-if="item.$message">{{ item.$message }}</span>
                    </div>
                  </div>

                  <div class="mt-4" v-if="this.isSeenPassword" >
                    <label for="password_confirm_eye" class="form-label">{{$t("confirm_new_password")}}</label>
                    <div class="form-icon right">
                      <input v-model="confirm_password" type="text" class="form-control form-control-icon" id="password_confirm_eye"
                             :class="{'is-invalid': submitted && v$.confirm_password.$error,}"
                             :placeholder="$t('type_in')">
                      <i @click="OnClickedPasswordVisibilityChange" class="ri-eye-line red-color cursor-pointer"></i>
                    </div>
                    <div v-for="(item, index) in v$.confirm_password.$errors" :key="index" class="red-color">
                      <span v-if="item.$message">{{ item.$message }}</span>
                    </div>
                  </div>
                  <div class="mt-4" v-else>
                    <label for="password_confirm_close_eye" class="form-label">{{$t("confirm_new_password")}}</label>
                    <div class="form-icon right">
                      <input v-model="confirm_password" v-if="!this.isSeenPassword" type="password" class="form-control form-control-icon" id="password_confirm_close_eye"
                             :class="{'is-invalid': submitted && v$.confirm_password.$error,}"
                             :placeholder="$t('type_in')">
                      <i @click="OnClickedPasswordVisibilityChange" class="ri-eye-off-line red-color cursor-pointer"></i>
                    </div>
                    <div v-for="(item, index) in v$.confirm_password.$errors" :key="index" class="red-color">
                      <span v-if="item.$message">{{ item.$message }}</span>
                    </div>
                  </div>

                  <div class="mt-3 d-grid gap-2">
                    <button @click="tryToResetPassword" class="btn submit" type="submit">{{$t("reset_password")}}</button>
                  </div>
                </form>

                <div class="d-flex justify-content-between link-sign-in text-center mt-3">
                  <RouterLink to="/login">
                    <span class="red-color">&nbsp;{{$t("sign_in")}}</span>
                  </RouterLink>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>

<script>
import Layout from "@/layouts/main";
import {
  required,
  helpers
} from "@vuelidate/validators";
import useVuelidate from "@vuelidate/core";
import userService from "@/apis/users-services";

export default {
  name: "reset-password",
  components: {
    Layout
  },
  setup(){
    return {
      v$: useVuelidate()
    }
  },
  data() {
    return {
      isSeenPassword: false,
      password: "",
      confirm_password: "",
      submitted: false,
      regError: "",
      regSuccess: "",
      tryingToRegister: false,
      isRegisterError: false,
      registerSuccess: false,
      checkedRest: false
    };
  },
  validations() {
    return{
      password: {
        required: helpers.withMessage("New Password is required", required),
      },
      confirm_password: {
        required: helpers.withMessage("Confirm New Password is required", required),
      },
    }
  },
  mounted() {
    document.documentElement.setAttribute("data-layout", "horizontal");
    document.documentElement.setAttribute("data-layout-width", "fluid");
    document.documentElement.setAttribute("data-topbar", "dark");
    document.documentElement.setAttribute("data-sidebar-size", "sm");
    document.documentElement.setAttribute("data-layout-position", "fixed"); //scrollable
    this.reset()
  },
  computed: {
    notification() {
      return this.$store ? this.$store.state.notification : null;
    },
  },
  methods: {
    OnClickedPasswordVisibilityChange(){
      this.isSeenPassword = !this.isSeenPassword;
    },
    async tryToResetPassword() {
      const {token, uuid} = this.$route.query;
      this.submitted = true;
      // stop here if form is invalid
      this.v$.$touch();

      if (this.v$.$invalid) {
        return;
      }else {
        this.tryingToRegister = true;
        // Reset the regError if it existed.
        this.regError = null;
        this.isRegisterError = false;

        this.$isLoading(true); // show loading screen
        await userService.resetPassword(this.password, token, uuid)
            .then((response) => {
              const {status, message} = response.data;
              if (status === "success"){
                this.tryingToRegister = false;
                this.registerSuccess = true;
                this.regSuccess = message;
              }else {
                this.tryingToRegister = false;
                this.isRegisterError = true;
                this.regError = message;
              }
            })
            .catch((error) => {
              this.tryingToRegister = false;
              this.regError = error ? error : "";
              this.isRegisterError = true;
            })
            .finally(() => {
              this.$isLoading(false);
            })
      }
    },
    async reset(){
      const {token, uuid} = this.$route.query;
      this.$isLoading(true); // show loading screen
      await userService.reset(uuid, token)
          .then((response) => {
            const {status, message} = response;
            if (status === "success"){
              this.checkedRest = true
            }else {
              this.tryingToRegister = false;
              this.isRegisterError = true;
              this.regError = message;
              this.checkedRest = false
              this.$router.push({
                path: '/login'
              })
            }
          })
          .catch((error) => {
            this.tryingToRegister = false;
            this.regError = error ? error : "";
            this.isRegisterError = true;
            this.checkedRest = false
            this.$router.push({
              path: '/login'
            })
          })
          .finally(() => {
            this.$isLoading(false);
          })
    }
  },
}
</script>

<style scoped>

</style>